.service {
    $self: &;

    position: relative;
  
    &.slide-wrapper {
        height: $a4-height-pdf;
        width: $a4-width-pdf;

        #{$self}__chapter {
            @include chapter;
      
            &__entry {
              @include chapter-entry;
            }
        
            &__page-count {
              @include chapter-count;
            }
          }

        #{$self}__image {
          @include background-image;
        }
    }
}