*,
*::after,
*::before {
  box-sizing: border-box;
}

.reveal .slides .slide-wrapper {
  text-align: left;
}

.reveal .slides>section.present, 
.reveal .slides>section>section.present {
  padding: 0;
}

// Scaling of A4 Documents (Preview)
.note-editor .slide-wrapper,
.form-group .a4 .slide-wrapper {
  transform: scale(0.751);
  transform-origin: top left;
}

.note-editor.note-airframe .note-editing-area .note-editable, .note-editor.note-frame .note-editing-area .note-editable {
  overflow: hidden;
  background-color: white;
}

/* Global Imports */
@import 'globals/colors';
@import 'globals/mixins';
@import 'globals/variables';

/* Slides */
@import 'slides/cover';
@import 'slides/energy-label';
@import 'slides/index';
@import 'slides/lodc';
@import 'slides/service';
@import 'slides/testimonial';
@import 'slides/thank-you';
@import 'slides/tire';
@import 'slides/who-is';

@font-face {
	font-family: Tex;
	src: url('fonts/liberation-sans/regular/LiberationSans-Regular.woff');
	-fs-pdf-font-embed: embed;
	-fs-pdf-font-encoding: Identity-H;
}

@media print {
  body *,
  html * {
    // background: yellow !important;
    font-family: Tex !important;
  }
}

.share-proposal {
  .alert {
    padding: 15px;
    margin-bottom: 20px;
  }

  object {
    width: 100%;
    height: 842px;
  }
}


#sortable, #sortable_pool {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 10px;
  width: 185px;
  height: 280px;
  border: 1px solid #ddd;
  border-radius: 4px;

  li {
    background-color: $primary;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    margin-bottom: 10px;
    width: 100px;
    cursor: move;
    list-style-type: none;
  }
}

#sortable_pool {
  li {
    background-color: #eeeeee;
    color: #999999;
  }
}

.right-arrow {
  padding-top: 30px;
  span {
    font-size: 84px;
  }
}

.proposal_checkbox_icon {
  border-radius: 50%;
  background-color: #fe9900;
  width: 35px;
  display: inline-block;
  height: 35px;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
}

.checkbox {
  .type_key_check,
  .vocation_check,
  .position_check {
    + label::before,
    + label::after {
      margin-top: 10px;
    }
  }
}

.brandheader {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.thumbnail {
  text-align: center;

  .form-check {
    margin-top: 20px;
  }

  .form-check-label {
    margin-bottom: 10px;
  }

  img.col {
    width: auto;
  }
}

.gallery-item {
  text-align: center;

  .thumbnail {
    display: inline-block;
    min-width: 270px;
  }

  }
