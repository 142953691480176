.tire {
  $self: &;

  &.slide-wrapper {
    background-color: $conti-white;
    color: $conti-black;
    font-family: map-get($fonts, default-sans-serif-regular);
    height: $a4-height-pdf;
    overflow: hidden;
    width: $a4-width-pdf;

    #{$self}__background-image {
      @include background-image;
    }

    #{$self}__content {
      display: inline-block;
      height: $a4-height-pdf;
      position: absolute;
      top: 35px;
      width: $a4-width-pdf;
      left: 0;
      line-height: 1.4;

      ul, ol {
        margin-top: 28px;
      }
    }

    #{$self}__headline {
      @include headline-h2;

      margin: 100px 0 0 0;
      padding-left: 64px;
      padding-right: 64px;
    }

    #{$self}__name {
      @include headline-h1;

      margin-top: 28px;
      margin-bottom: 0;
      text-transform: none;
      padding-left: 64px;
      padding-right: 64px;
    }

    #{$self}__chapter {
      @include chapter;

      &__entry {
        @include chapter-entry;
      }
  
      &__page-count {
        @include chapter-count;
      }
    }

    #{$self}__text {
      padding-left: 64px;
      padding-right: 64px;
      display: flex;
      align-items: center;

      #{$self}__image {
        padding-top: 0;
        width: 100%;
      }

      &__features-and-application {
        width: 60%;
        min-height: 350px;
      }

      &__pictures {
        width: 40%;
        text-align: center;
      }

      &__position {
        text-align: left;
        padding-top: 10px;
        display: flex;
        align-items: center;
      }

      &__position-image {
        width: 40px;
        height: 40px;
        margin: 0 5px;
      }
    }

    #{$self}__logo {
      width: 50px;
      height: 50px;
      padding-left: 5px;
      padding-right: 5px;
      object-fit: contain;
      object-position: center center;
    }

    #{$self}__text__copy-headline {
      color: $conti-gray;
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
      text-align: left;
      text-transform: uppercase;
    }

    #{$self}__name__subline {
      font-weight: 100;
      font-size: 28px;
      padding-left: 64px;
      padding-right: 64px;
      margin-bottom: 28px;
      display: block;
  }

    #{$self}__text ul li,
    p.copy-text {
      font-size: 16px;
      text-align: left;
    }

    ul {
      list-style: none;
      margin: 0;
      padding-left: 10px;

      li {
        margin-bottom: 5px;
        &::before {
          color: $conti-black;
          content: "\2022";
          display: inline-block;
          font-weight: bold;
          margin-left: -1em;
          width: 1em;
        }
      }
    }

    #{$self}__details {
      margin-top: 30px;
      padding: 0 64px;

      #{$self}__details__table {
        border-collapse: collapse;
        font-size: 12px;
        text-align: left;
        width: 100%;

        tr {
          &:nth-child(3) {
            th {
              border-top: none;
              font-weight: normal;
              padding-top: 0;
            }
          }
        }

        th {
          background-color: $conti-light;
          font-size: 13px;
          padding: 8px;
          text-align: left;
          font-family: map-get($fonts, default-sans-serif-bold);
          font-weight: 600;

          &#{$self}__details-table_gradient {
            background: linear-gradient(to left, $conti-light, $conti-orange);
            height: 3px;
            padding: 0;
          }
        }

        td {
          border-bottom: 1px solid $conti-gray;
          font-size: 13px;
          padding: 5px;
          text-align: left;
        }
      }
    }

    #{$self}__footnote {
      text-align: right;
      width: 100%;
      float: right;
      padding-right: 64px;
      position: absolute;
      bottom: 145px;
      font-size: 12px;
    }
  }
}
