.lodc {
  position: absolute;

  $self: &;

  &.slide-wrapper {
    height: $a4-height-pdf;
    overflow: hidden;
    width: $a4-width-pdf;

    #{$self}__background-image {
      @include background-image;
    }

    #{$self}__chapter {
      @include chapter;

      &__entry {
        @include chapter-entry;
      }
  
      &__page-count {
        @include chapter-count;
      }
    }
  
    #{$self}__content {
      z-index: 1;
      position: relative;
      margin: 47px;

      #{$self}__logo {
        width: 30%;
        float: right; 
        margin-top: 60px;
      }

      h1#{$self}__headline {
        @include headline-h1;

        width: 70%;
        margin-top: 60px;
      }

      h2#{$self}__headline {
        @include headline-h2;

        width: 100%;
        color: $conti-black;
        text-transform: none;
        font-family: map-get($fonts, default-sans-serif-regular);
        margin: 0 0 28px 0;
      }

      h1#{$self}__headline,
      h2#{$self}__headline {
        float: left;
      }

      #{$self}__tire-wrapper {
        width: 100%;
        margin-top: 32px;
        position: relative;

        #{$self}__tire-list {
          padding-left: 36px;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin: 0;

          &__title {
            @include copy-small;
          
            background: linear-gradient(180deg, $conti-light 0%, $conti-white 100%);
            padding: 4px;
            transform: rotate(180deg);
            font-weight: bold;
            writing-mode: vertical-lr;
            margin: 0 8px 0 0;
            font-size: 14px;
            position: absolute;
            left: 0;
            bottom: 0;
            font-weight: 700;
          }

          #{$self}__tire {
            width: 25%;
            display: flex;
            flex-direction: column;
            padding: 0 8px;

            &__image-wrapper {
              height: 100px;
              padding: 0 5px 5px;
              width: calc(100% - 10px);
              align-self: center;
              position: relative;

              img {
                object-fit: contain;
                display: block;
                height: 100%;
                width: 100%;
                object-position: center center;
              }
            }
  
            #{$self}__tire__name-wrapper {
              width: 100%;
              align-self: flex-start;
              background-color: $conti-orange;
              padding: 4px;
              margin: 0;
              min-height: 28px;
              flex-grow: 1;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                text-align: center;
                font-family: map-get($fonts, default-sans-serif-bold);
                font-size: 12px;
                line-height: 11px;
                font-weight: 600;
                margin: 0;
              }
            }
          }
        }
      }
    }
  
    li#{$self}__conticonnect {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
