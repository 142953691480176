.who-is {
  $self: &;

  &.slide-wrapper {
    #{$self}__background-image {
      @include background-image;
    }
    
    #{$self}__chapter {
      @include chapter;
      margin-bottom: 10px;

      &__entry {
        @include chapter-entry;
      }
  
      &__page-count {
        @include chapter-count;
      }
    }

    #{$self}__header {
      margin: 0 47px 47px 47px;
      width: calc(100% - 94px);
      z-index: 1;
      position: relative;
    }

    #{$self}__header--col-2 {
      #{$self}__header {
        margin: 0 0 47px 47px;
        width: calc(50% - 50px);
      }
  
      #{$self}__header:last-child {
        margin: 0 47px 47px 0;
        float: right;
      }
    }

    #{$self}__sub-headline {
      font-size: 23px;
    }
  

    #{$self}__content {
      z-index: 1;
      position: relative;
      margin: 0 47px;

      #{$self}__headline {
        @include headline-h1;

        margin-top: 0;
        margin-bottom: 10px;
      }

      #{$self}__category-headline {
        @include headline-h3;
      }

      #{$self}__copy-text {
        @include copy-small;

        max-width: 90%;

        &.small-text {
          @include copy-smallest;
        }
      }

      #{$self}__highlight-list,
      #{$self}__image-list {
        display: flex;
        list-style: none;
        padding: 0;
        flex-wrap: nowrap;
        width: 100%;
        align-items: end;
    
        li {
          padding: 0 10px;
          width: 25%;
          display: flex;
          flex-flow: column;
    
          img {
            width: 100%;
          }
    
          &:first-child {
            padding-left: 0;
          }
    
          &:last-child {
            padding-right: 0;
          }

          #{$self}__category-copy {
            @include headline-h3;

            margin-bottom: 6px;
    
            &--highlight {
              font-size: 24px;
              line-height: 1;
            }
          }
        }
      }
    
      #{$self}__image-list {
        align-items: center;
      }

      p {
        margin: 0 0 10px;
      }
    }
  }
}