@import './fonts';

@mixin headline-h1 {
  color: $conti-black;
  font-family: map-get($fonts, default-sans-serif-bold);
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

@mixin headline-h2 {
  color: $conti-orange;
  font-family: map-get($fonts, default-sans-serif-bold);
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
}

@mixin headline-h3 {
  font-family: map-get($fonts, default-sans-serif-bold);
  width: 100%;
  border-bottom: 1px solid $conti-gray;
  font-size: 10px;
  color: $conti-gray;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 12px;
  padding-bottom: 5px;
  text-align: left;
}

@mixin copy-default {
  font-size: 16px;
  line-height: 1.1;
  font-family: map-get($fonts, default-sans-serif-regular);
  color: $conti-black;
  font-weight: 300;
  margin-bottom: 14px;
  text-align: left;
}

@mixin copy-bold {
  @include copy-default;

  font-family: map-get($fonts, default-sans-serif-bold);
  font-weight: 600;
}

@mixin copy-small {
  font-size: 12px;
  line-height: 1.1;
  font-family: map-get($fonts, default-sans-serif-regular);
  color: $conti-black;
  font-weight: 300;
  text-align: left;
}

@mixin copy-smallest {
  @include copy-small;

  font-size: 10px;
}

@mixin chapter {
  float: right;
  margin-right: 47px;
  margin-top: 47px;
  z-index: 1;
  position: relative;
}

@mixin chapter-entry {
  font-family: map-get($fonts, default-sans-serif-regular);
  font-size: 12px;
  font-weight: 300;
  text-transform: none;

  &::after {
    content: '|';
    position: relative;
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
  }
}

@mixin chapter-count {
  color: $conti-orange;
  font-family: map-get($fonts, default-sans-serif-bold);
  font-size: 12px;
  font-weight: 600;
}

@mixin background-image {
  height: $a4-height-pdf;
  width: $a4-width-pdf;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}
