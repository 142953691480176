.thank-you {
  $self: &;

  &.slide-wrapper {
    height: $a4-height-pdf;
    width: $a4-width-pdf;

    #{$self}__background-image {
      @include background-image;
    }

    #{$self}__header-wrapper {
      display: flex;
      overflow: hidden;
      align-items: center;
      margin: 64px 64px 0 64px;
    }

    #{$self}__header {
      z-index: 1;
      position: relative;
      width: 100%;
    }

    #{$self}__content {
      margin: 0 64px;
      z-index: 1;
      position: relative;

      h1#{$self}__headline {
        @include headline-h1;

        color: $conti-orange;
        margin: 20px 0 40px 0;
      }
        
      #{$self}__copy-text {
        @include copy-default;

        &__name {
          color: $conti-orange;
          font-weight: bold;
          padding-top: 22px;
          display: block;
        }
      }
    }
  }
}