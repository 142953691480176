.cover {
  $self: &;

  &.slide-wrapper {
    background-color: $conti-white;
    color: $conti-black;
    font-family: map-get($fonts, default-sans-serif-regular);
    height: $a4-height-pdf;
    overflow: hidden;
    width: $a4-width-pdf;
  
    #{$self}__img {
      margin: 25px;
      width: calc(100% - 50px);
    }
  
    #{$self}__logo {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  
    #{$self}__text {
      font-size: 16px;
      padding-left: 64px;
      padding-right: 64px;
      position: absolute;
      top: calc(50% + 30px);
    }
  
    h1#{$self}__headline {
      @include headline-h1;

      margin: 0 0 14px;
      top: calc(50% + 70px);

      span {
        font-family: map-get($fonts, default-sans-serif-regular);
      }
    }
  
    #{$self}__name {
      @include copy-bold;
    }

    #{$self}__email,
    #{$self}__phone {
        &::after {
            content: ":";
            display: inline-block;
            margin-right: 2px;
        }
      }
  
    #{$self}__fleet,
    #{$self}__user,
    #{$self}__dealer {
      @include copy-default;

      margin: 0 0 10px;
    }
  
    #{$self}__footer {
      bottom: 47px;
      font-size: 16px;
      padding-left: 64px;
      position: absolute;
    }
  }
}
