.testimonial {
  $self: &;

  &.slide-wrapper {
    color: $conti-black;
    font-family: map-get($fonts, default-sans-serif-regular);
    height: $a4-height-pdf;
    overflow: hidden;
    width: $a4-width-pdf;
    position: relative;

    #{$self}__chapter {
      @include chapter;

      &__entry {
        @include chapter-entry;
      }
  
      &__page-count {
        @include chapter-count;
      }
    }

    #{$self}__background-image {
      @include background-image;
    }

    #{$self}__text-wrapper {
      display: inline-block;
      margin-top: 160px;
      padding-left: 55px;
      padding-right: 55px;
      z-index: 1;
      position: relative;
      text-align: left;
    }

    h1#{$self}__headline {
      @include headline-h1;

      margin: 20px 0 10px 0;
    }

    h2#{$self}__headline {
      @include headline-h2;

      margin: 10px 0 0 0;
    }

    #{$self}__copy-testimony {
      font-size: 14px;
      margin: 30px 0 0 0;
    }

    p {
      margin: 0 0 10px;
      font-size: 14px;
    }

    #{$self}__copy-author {
      @include copy-default;

      color: $conti-gray;
      font-size: 12px;
      font-weight: 600;
      margin: 10px 0 0 0;
    }
  }
}