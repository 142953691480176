.index {
  $self: &;

  &.slide-wrapper {
    #{$self}__background-image {
      @include background-image;
    }

    #{$self}__content {
      z-index: 1;
      position: relative;
      margin: 0 47px 47px 47px;
      padding-top: 65px;
      font-family: map-get($fonts, default-sans-serif-regular);

      h1 {
        @include headline-h1;

        margin: 0 0 20px 0;
      }
    
      #{$self}__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
  
        li {
          display: flex;
          width: 100%;    
          align-items: baseline;
          margin-bottom: 12px;

          &#{$self}__subpage {
            padding-left: 15px;
          }

          /* Reset the stupid 10px margin for paragraphs :( */
          p {
            @include copy-default; 
            margin: 0;
          }

          .entry {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            #{$self}__entry,
            #{$self}__number {
              @include copy-default; 
              margin: 0;
            }

            #{$self}__dots {
              flex: 1;
              border-bottom: 1px dotted $conti-gray;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}