.energy-label {
    $self: &;

    &.slide-wrapper {
        background-color: $conti-white;
        color: $conti-black;
        font-family: map-get($fonts, default-sans-serif-regular);
        height: $a4-height-pdf;
        overflow: hidden;
        width: $a4-width-pdf;
    
        #{$self}__background-image {
          @include background-image;
        }
    
        #{$self}__content {
          display: inline-block;
          height: $a4-height-pdf;
          position: absolute;
          top: 35px;
          width: $a4-width-pdf;
        }
    
        #{$self}__headline {
          @include headline-h1;
    
          margin: 60px 0 0 0;
          padding-left: 47px;
          font-weight: 300;
          padding-right: 47px;
          font-size: 28px;

          strong {
            color: $conti-orange;
            font-weight: 600;
          }
        }

        #{$self}__chapter {
          @include chapter;
    
          &__entry {
            @include chapter-entry;
          }
      
          &__page-count {
            @include chapter-count;
          }
        }
    
        #{$self}__wrapper{
            margin-top: 10px;
            padding-left: 47px;
            padding-right: 47px;
    
          #{$self}__image {
            filter: drop-shadow(5px 5px 5px #222);
            width: 49%;
          }
        }
      }
    }
